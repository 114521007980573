import "owl.carousel"


jQuery(document).ready(function ($) {

  // MOBILE MENU
  // $('.mobile-menu-icon').on('click', function () {
  //   $("#menu-navbar").toggleClass('active')
  //   $(".line").toggleClass('active')
  //   $("#header").toggleClass('active')
  //   $("#nav-container").toggleClass('active')
  //   $(".sub-menu").toggleClass("active")
  //   $(".menu-navbar-container").toggleClass('active')
  // })

  // $('.menu-item-object-page').on('click', function () {
  //   if ($('#menu-navbar').hasClass('active')) {
  //     $("#menu-navbar)").toggleClass('active')
  //     $(".line").toggleClass('active')
  //     $("#header").toggleClass('active')
  //     $("#nav-container").toggleClass('active')
  //     $(".sub-menu").toggleClass("active")
  //     $(".menu-navbar-container").toggleClass('active')
  //   }
  // })

  // FEATURE CAROUSEL BLOCK
  $('.feature-carousel-container').owlCarousel({
    loop: true,
    nav: true,
    dots: false,
    margin: 55,
    items: 1,
    startPosition: 0, 
    center: true,
    navText: [
      '<div class="owl-custom-nav-prev"><i class="fal fa-arrow-left"></i></div>',
      '<div class="owl-custom-nav-next"><i class="fal fa-arrow-right"></i></div>'
    ]
  })

  // BRANDS CAROUSEL BLOCK
  $('.brands-carousel-container').owlCarousel({
    loop: true,
    nav: true,
    dots: false,
    loop: true,
    autoplay: true,
    autoplayTimeout: 1000,
    margin: 55,
    items: 6,
    startPosition: 0,
    nav: false,
    dots: false,
    navText: [
      '<div class="owl-custom-nav-prev"><i class="fal fa-arrow-left"></i></div>',
      '<div class="owl-custom-nav-next"><i class="fal fa-arrow-right"></i></div>'
    ],
	responsive: {
		0: {
			items: 1
		},
		480: {
			items: 2
		},
		768: {
			items: 3
		},
		992: {
			items: 6
		}
		
	}
  })

  //ACCORDION BLOCK
  $('.accordion-content').slideUp();
  $('.close-accordion').hide()

  $('.accordion-toggle').click(function () {
    const $accordionItem = $(this).closest('.accordion-item');
    const $content = $accordionItem.find('.content');
    const $open = $accordionItem.find('.open-accordion');
    const $close = $accordionItem.find('.close-accordion');

    $open.toggle()
    $close.toggle()

    if ($accordionItem.hasClass('active')) {
      $content.slideUp();
      $accordionItem.removeClass('active');
    } else {
      $('.accordion-item').removeClass('active');
      $content.slideDown();
      $accordionItem.addClass('active');
    }
  });


  // SMF FORM SUBMISSION
  $('.newsletter').on('submit', function (e) {
    e.preventDefault();
    const DATA = $(this).serialize();

    $.ajax({
      type: 'POST',
      url: $(this).attr('action'),
      dataType: 'json',
      data: DATA,
      xhrFields: {
        withCredentials: false,
      },
      success: function (data) {
        $('.inputs-wrap').html('<p class="newsletter-thanks">Thanks for signing up!</p>');
      },
      error: function (err) {
        console.log(err);
      }
    });
  });

  // IFRAME WRAPPER
  $(document).ready(function () {
    $("main iframe").each(function () {
      $(this).wrap('<div class="embed-container full-width"></div>');
    });
  });

  // MASONRY GALLERY
  setTimeout(function() {
    $('.wp-block-migb-masonry-gallery.show-less').wrap('<div class="gallery-wrapper close"></div>')
    $('.wp-block-columns').wrap('<div class="container wp-native-block"></div>')
    $('.wp-block-heading, main > p, main > ul, main > ol, main > h1, main > h2, main > h3').wrap('<div class="container"></div>')
  }, 300)

  // SHOW MORE
  if ($('.wp-block-migb-masonry-gallery.show-less').children().length > 6) {
    $('.wp-block-migb-masonry-gallery').after('<div class="gallery-btn-wrapper" ><a class="btn show-more">View More</a></div');
    $(".show-more").click(function () {
      $('.wp-block-migb-masonry-gallery > *:nth-child(n+7)').css('display', 'block');
      $('.gallery-wrapper').toggleClass('close')
      $('.show-more').hide()
    });
  }
});